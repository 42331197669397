import React from "react";

import PageWrapper from "../../components/PageWrapper";
import Hero from "../../sections/telecommute/Hero";
import ContentOne from "../../sections/telecommute/ContentOne";
import ContentTwo from "../../sections/telecommute/ContentTwo";
import ContentThree from "../../sections/telecommute/ContentThree";
import ContentFour from "../../sections/telecommute/ContentFour";

const Telecommute = () => {
  return (
    <>
      <PageWrapper>
        <Hero className="bg-default-3 pt-25 pt-lg-29" />
        <ContentOne className="mt-15 pt-27 pt-lg-35 pb-11 pb-lg-34 " />
        <ContentThree className="bg-dark-cloud pt-24 pt-lg-24 pb-15 pb-lg-30" />
        <ContentTwo className="pt-27 pb-13 pb-lg-34" />
        <ContentFour className="bg-images" />
      </PageWrapper>
    </>
  );
};
export default Telecommute;
